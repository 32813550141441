<template>
    <div>
        <div v-if="coupon != null">
            <!-- app bar -->
            <top-nav title="Coupon list">
                <template v-slot:actions>
                    <v-btn icon @click="save"><v-icon>mdi-floppy</v-icon></v-btn>
                </template>
            </top-nav>

            <v-card flat tile color="grey lighten-5">
                <v-card-text>
                    <v-slide-group show-arrows class="mb-3">
                        <v-slide-item>
                            <v-chip class="mr-2" small :color="coupon.state == 'Draft' ? 'warning' : 'success'" @click="changeState">
                                {{ coupon.state }}
                            </v-chip>
                        </v-slide-item>
                    </v-slide-group>

                    <v-divider class="mb-3"></v-divider>

                    <!-- GENERAL INFORMATION -->
                    <v-form ref="form">
                        <v-card-subtitle class="pl-0 subtitle-1">General Information</v-card-subtitle>

                        <v-text-field v-model="coupon.code" label="Code" outlined background-color="white" :rules="codeRules"></v-text-field>

                        <v-textarea v-model="coupon.description" label="description" outlined background-color="white"></v-textarea>

                        <v-select v-model="coupon.type" :items="couponTypes" label="Type" outlined background-color="white"></v-select>

                        <v-text-field v-model="coupon.amount" label="Amount" outlined background-color="white" type="numeric" v-intonly
                            :prefix="coupon.type == 'Amount' ? '$' : ''" :suffix="coupon.type == 'Percentage' ? '%' : ''" :rules="amountRules"></v-text-field>

                        
                        <!-- RULES -->
                        <v-card-subtitle class="pl-0 subtitle-1">Rules</v-card-subtitle>

                        <v-dialog ref="expirationDate" v-model="expirationDateDialog" width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="coupon.rules.expirationDate" label="Expiration Date" outlined background-color="white" class="txt-middle" v-bind="attrs"
                                    v-on="on" hint="Set an expiration date for this coupon" persistent-hint readonly></v-text-field>
                            </template>

                            <v-card>
                                <div class="subtitle-1 text-center">Expiration Date</div>
                                <v-date-picker v-model="coupon.rules.expirationDate" no-title @input="expirationDateDialog = false" scrollable></v-date-picker>
                                <v-btn depressed block tile color="secondary" @click="coupon.rules.expirationDate = null; expirationDateDialog = false">Clear</v-btn>
                            </v-card>
                        </v-dialog>

                        <v-text-field v-model="coupon.rules.minItems" label="Min. Items" outlined background-color="white" type="numeric" v-intonly></v-text-field>
                        <v-text-field v-model="coupon.rules.minCartAmount" label="Min. Cart Amount" outlined background-color="white" type="numeric" v-intonly></v-text-field>
                        <v-text-field v-model="coupon.rules.maxUse" label="Max Use" outlined background-color="white" type="numeric" v-intonly></v-text-field>

                        <v-row no-gutters>
                            <v-col cols="10">
                                Can be used with others
                            </v-col>
                            <v-col cols="2" justify-end>
                                <v-switch v-model="coupon.rules.canCombine" class="mt-0"></v-switch>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>

            <v-overlay :value="saving">
                <v-card flat color="white" min-width="200">
                    <v-card-text>
                        <div class="subtitle-1 grey--text text--darken-2 text-center mb-2">Saving...</div>
                        <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-overlay>
        </div>

        <v-skeleton-loader class="mx-auto" type="article, article, article, actions" v-else></v-skeleton-loader>
    </div>
</template>

<script>
import TopNav from '../../components/admin/TopNav.vue';
import { CouponType, State } from '../../models';
import { PetuCoupon } from '../../classes/coupon';
import { _st } from '../../softech';

export default {
    props: {
        couponId: { type: String, default: '' },
    },
    data() {
        return {
            coupon                  : null,

            couponTypes             : [
                { text: 'Amount', value: CouponType.AMOUNT },
                { text: 'Percentage', value: CouponType.PERCENTAGE },
            ],

            codeRules               : [ v => !!v || 'Coupon code is required' ],
            amountRules             : [ 
                v => !!v || 'An amount is required',
                v => _st.isNumeric( v ) || 'Must be a valid amount',
            ],

            expirationDateDialog    : false,
            saving                  : false,
        }
    },
    async mounted() {
        // load coupon
        let coupon = new PetuCoupon();

        if( _st.isNU( this.couponId ) || _st.isEmpty( this.couponId ) ) {
            this.coupon = coupon.data;
            return;
        }

        await coupon.load( this.couponId );
        this.coupon = coupon.data;
    },
    methods: {
        async save() {
            if( !this.$refs.form.validate() )
                return;

            let coupon = new PetuCoupon( this.coupon );
            const isNew = _st.isNU( coupon.data.id ) || _st.isEmpty( coupon.data.id );

            this.saving = true;
            await coupon.save();

            if( isNew ) {
                this.$router.push( '/secure/coupon/' + coupon.data.id );
                return;
            }

            this.saving = false;
            this.coupon = coupon.data;
        },
        changeState() {
            if( this.coupon.state == State.DRAFT )
                this.coupon.state = State.PUBLISHED;
            else
                this.coupon.state = State.DRAFT;
        }
    },
    directives: {
        intonly: {
            inserted( el ) {
                el.onkeydown = ( e ) => {
                    const key       = e.keyCode;
                    const ctrlFlag  = e.ctrlKey || e.metaKey;
                    
                    if ([46, 8, 9, 27, 13, 190, 110].includes(key) ||    // allow: backspace, delete, tab, escape, enter and .
                        (key == 65 && ctrlFlag) ||                  // allow: ctrl/cmd+A
                        (key == 67 && ctrlFlag) ||                  // allow: ctrl/cmd+C
                        (key == 88 && ctrlFlag) ||                  // allow: ctrl/cmd+X
                        (key >= 35 && key <= 39)) {                 // allow: home, end, left, right
                        return;                                     // do nothing
                    }
            
                    /** if it's a number, stop the keypress */
                    if ((e.shiftKey || (key < 48 || key > 57)) && (key < 96 || key > 105)) {
                        e.preventDefault();
                    }
                }
            }
        }
    },
    components: {
        TopNav
    }
}
</script>

<style lang="scss" scoped>

</style>